import Router from "next/router";
import { ToastError, ToastSuccess } from "src/common/Toast";
import { routerConstant } from "src/constants/routerConstant";
import { removeCookie } from "src/utils/cookie";

export const handleResultApi = (response) => {
  if (response?.success && response?.code == 401) {
    removeCookie();
  }
  if (response?.success && response?.code == 403) {
    Router.push(routerConstant.page404).then();
  }
  return response?.success ? ToastSuccess(response.message) : ToastError(response.message);
};
