import Cookies from "universal-cookie";

export const addCookie = ({ accessToken, phoneNumber }) => {
  const cookies = new Cookies();

  cookies.set("jwt", accessToken);
  localStorage.setItem("jwt", accessToken);

  cookies.set("phoneNumber", phoneNumber);
  localStorage.setItem("phoneNumber", phoneNumber);
};

export const removeCookie = () => {
  const cookies = new Cookies();

  cookies.remove("jwt");
  localStorage.removeItem("jwt");
  cookies.remove("phoneNumber");
  localStorage.removeItem("phoneNumber");
};
