import { Row, Col, Modal } from "antd";
import React from "react";
import { useRecoilState } from "recoil";
import { confirmPopupState, serviceBookingState } from "src/recoil/commonRecoilState";
import useWindowSize from "src/hooks/useWindowSize";
import { FiCheckCircle } from "react-icons/fi";
import { routerConstant } from "src/constants/routerConstant";
import { useRouter } from "next/router";

export const CardItem = ({ card, index }) => {
  return (
    <div className="tw-w-[200px] tw-min-h-[220px] tw-bg-white tw-rounded-[20px] tw-shadow-2xl tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-between tw-py-[22px] tw-px-[15px]">
      <div className="tw-flex tw-flex-col tw-items-center">
        <FiCheckCircle fontSize={50} color="green" className="tw-mb-[15px]" />
        <div className="tw-text-[25px] tw-leading-[25px] tw-text-red tw-font-bold">
          {card.title}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-min-h-[48px]">
        <div className="tw-text-[16px] tw-leading-[22px] tw uppercase tw-shrink-0 tw-mb-[10px]">
          {card.name}
        </div>
        <div
          className={`${
            index > 1 ? "tw-text-[26px] tw-leading-[26px]" : "tw-text-[22px] tw-leading-[22px]"
          } tw-font-bold tw-flex-1 tw-mt-auto`}
        >
          {card.value}
        </div>
      </div>
    </div>
  );
};

Date.prototype.formatDMY = function () {
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  return [padTo2Digits(this.getDate()), padTo2Digits(this.getMonth() + 1), this.getFullYear()].join(
    "/",
  );
};
const ConfirmPopup = () => {
  const router = useRouter();

  const [confirmPopup, setConfirmPopup] = useRecoilState(confirmPopupState);
  const [serviceBooking, setServiceBooking] = useRecoilState(serviceBookingState);
  const { width } = useWindowSize();

  const BookingInfo = serviceBooking
    ? [
        {
          title: "Loại xe",
          value: serviceBooking?.carMarker?.name,
        },
        {
          title: "Gói rửa xe",
          name: serviceBooking?.service?.name,
          value: Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(
            serviceBooking?.service?.price,
          ),
        },
        {
          title: "Dịch vụ khác",
          name: serviceBooking?.integrated?.name,
          value: serviceBooking?.integrated?.price
            ? Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(
                serviceBooking?.integrated?.price,
              )
            : "",
        },
        {
          title: "Thời gian",
          name: new Date(serviceBooking?.date).formatDMY(),
          value: serviceBooking?.time?.timingRender,
        },
      ]
    : [];

  const handleCancel = () => {
    setConfirmPopup({ show: false, status: "", isLoggedIn: true });

    setServiceBooking({});
    if (!confirmPopup?.isLoggedIn && !confirmPopup?.payload?.hasCustomer) {
      router.push(routerConstant.profile);
    }
  };

  return (
    <Modal
      open={confirmPopup.show}
      onCancel={handleCancel}
      footer={null}
      width={width > 768 ? 650 : null}
      maskClosable={false}
    >
      <div className="tw-text-black tw-text-center">
        <div className="tw-italic tw-mb-[20px]">
          <h2 className="tw-text-[30px] tw-leading-[50px] tw-font-bold tw-mb-[5px]">
            Đặt lịch thành công!
          </h2>
          <div className="tw-text-[16px] tw-leading-[22px] tw-font-light">
            Cảm ơn Quý khách đã đặt lịch tại Autoshine.
          </div>

          {!confirmPopup?.isLoggedIn && !confirmPopup?.payload?.hasCustomer && (
            <div>
              <div className="tw-text-[16px] tw-leading-[22px] tw-font-light">
                Chúng tôi đã tạo tài khoản tương ứng cho quý khách với
              </div>
              <div className="tw-text-[16px] tw-leading-[22px] tw-font-light">
                {`Tên đăng nhập: ${confirmPopup?.payload?.phoneNumber} và mật khẩu: ${confirmPopup?.payload?.phoneNumber}`}
              </div>
            </div>
          )}
        </div>

        <div className="md:tw-w-[500px] tw-w-full tw-mx-auto tw-p-[20px]">
          <Row gutter={[48, 46]}>
            {BookingInfo.map((info, index) => {
              return (
                <Col xl={12} lg={12} md={12} sm={24} xs={24} key={index}>
                  <CardItem card={info} index={index} />
                </Col>
              );
            })}
          </Row>
        </div>

        <div>
          <button
            className="tw-w-[235px] tw-h-[44px] tw-mx-auto tw-mt-[36px] tw-text-center tw-text-white tw-bg-button tw-text-[20px] tw-leading-[28px] tw-uppercase tw-tracking-[7px] tw-font-bold"
            onClick={handleCancel}
          >
            Xác nhận
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmPopup;
