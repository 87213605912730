import { atom } from "recoil";

export const authStateDefault = {
  status: false,
  jwt: "",
  phoneNumber: "",
};

export const authState = atom({
  key: "auth-state",
  default: authStateDefault,
});
