import { Form, Input, InputNumber, message, Modal } from "antd";
import { useRecoilState } from "recoil";
import { OrderPopupState } from "src/recoil/commonRecoilState";
import { ButtonSubmit } from "src/common/BookingPopup/BookingPopup";
import { isRegexVietnamesePhoneNumber } from "src/validations/index";
import { useGetUserInfo } from "src/react-query/hooks/user";
import { useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import { ToastError } from "src/common/Toast";
import { useOrderProducts } from "src/react-query/hooks/product";

const initialValues = {
  amount: 1,
  // name: "",
  phone_number: "",
  address: "",
  note: "",
};

const InputNumberCustom = ({ value, onChange }) => {
  return (
    <div className="tw-flex tw-items-center">
      <span
        onClick={() =>
          onChange(value > 1 ? value - 1 : value)
        }
        style={{border:'1px solid #d9d9d9', borderRightWidth: 0}}
        className="tw-cursor-pointer tw-border-solid tw-border-1 tw-border-[#d9d9d9] tw-h-[32px] tw-w-[32px] tw-text-black tw-flex tw-items-center tw-justify-center tw-font-bold"
      >
        {" "}
        -{" "}
      </span>
      <InputNumber
        className="input-number-center remove-step tw-text-center tw-w-[40px]"
        value={value}
        min={1}
        max={5}
        defaultValue={1}
      />
      <span
           style={{border:'1px solid #d9d9d9' , borderLeftWidth: 0}}
        onClick={() =>
          onChange(value >= 5 ? value : value + 1)
        }
        className="tw-cursor-pointer tw-h-[32px] tw-w-[32px] tw-bg-white tw-text-black tw-flex tw-items-center tw-justify-center tw-font-bold"
      >
        {" "}
        +{" "}
      </span>
    </div>
  );
};
const OrderPopup = () => {
  const [orderPopup, setOrderPopup] = useRecoilState(OrderPopupState);
  const { show, order } = orderPopup;
  const [form] = Form.useForm();
  const watchAmount = Form.useWatch(["amount"], form) || 1;

  const userQuery = useGetUserInfo();
  const dataUser = userQuery?.data?.data?.user;

  const orderProductsMutation = useOrderProducts();
  useEffect(() => {
    if (dataUser) {
      form.setFieldsValue({
        ...initialValues,
        phone_number: dataUser?.phone_number,
        amount: order?.quantity,
      });
      return;
    }
    form.setFieldsValue({ ...initialValues, amount: order?.quantity });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser, orderPopup]);

  const handleCancel = () => {
    setOrderPopup({
      show: false,
      order: null,
    });
    form.resetFields();
  };

  const submitForm = async (data) => {
    if (!order?.id) {
      ToastError("Sản phẩm không tồn tại");
      return;
    }
    const { address, phone_number, amount, note, name } = data;
    const payload = {
      address,
      phone_number,
      name,
      note,
      products: [{ id: order?.id, amount }],
    };
    const res = await orderProductsMutation.mutateAsync(payload);
    if (res?.success) {
      handleCancel();
      message.success("Đặt hàng thành công");
    } else {
      message.error("Đặt hàng thất bại");
    }
    // message.success("Đặt hàng thành công");
    handleCancel();
  };

  return (
    <Modal open={show} footer={null} onCancel={handleCancel} maskClosable={false}>
      <div className="tw-py-[26px] tw-px-[6px] tw-text-black ">
        <div className="tw-mb-[16px] tw-italic">
          <div className="tw-text-[34px] tw-leading-[40px] tw-uppercase tw-font-bold tw-mb-[20px] ">
            xin chào!
          </div>
          <p className="tw-text-[15px] tw-leading-[20px] tw-font-light">
            Cảm ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi.
          </p>
        </div>

        <div className="" style={{ width: "100%" }}>
          <Form form={form} onFinish={submitForm}>
            <Form.Item
              label={<p className="tw-font-medium tw-text-[16px] tw-relative">Nhập số lượng</p>}
              labelCol={{ span: 24 }}
              name="amount"
              className="tw-mb-[10px]"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số lượng",
                },
              ]}
            >
             <InputNumberCustom/>
            </Form.Item>

            <Form.Item
              label={<p className="tw-font-medium tw-text-[16px] tw-relative">Họ và tên</p>}
              labelCol={{ span: 24 }}
              name="name"
              className="tw-mb-[10px]"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập họ và tên",
                },
              ]}
            >
              <Input size="large" placeholder="Nguyễn Văn A" autoComplete="off" autoFocus={true} />
            </Form.Item>
            <Form.Item
              label={<p className="tw-font-medium tw-text-[16px] tw-relative">Số điện thoại</p>}
              labelCol={{ span: 24 }}
              name="phone_number"
              className="tw-mb-[10px]"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại",
                },
                () => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (!isRegexVietnamesePhoneNumber(value)) {
                      return Promise.reject("Nhập đúng định dạng số điện thoại");
                    } else return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input size="large" placeholder="0123456789" autoComplete="off" />
            </Form.Item>
            <Form.Item
              label={<p className="tw-font-medium tw-text-[16px] tw-relative">Địa chỉ nhận hàng</p>}
              labelCol={{ span: 24 }}
              name="address"
              className="tw-mb-[10px]"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập địa chỉ",
                },
              ]}
            >
              <Input size="large" placeholder="Số nhà ..." autoComplete="off" />
            </Form.Item>

            <Form.Item
              label={<p className="tw-font-medium tw-text-[16px] tw-relative">Ghi chú</p>}
              labelCol={{ span: 24 }}
              name="note"
              className="tw-mb-[10px]"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea size="large" placeholder="Ghi chú ..." autoComplete="off" />
            </Form.Item>

            {order ? (
              <div className=" tw-font-medium tw-text-[20px]">
                <div className="tw-italic tw-font-bold tw-text-[24px]">{order.type}:</div>
                <div>Tên: {order.name}</div>
                <div>Số lượng: {watchAmount}</div>
                <div>
                  Giá 1 sản phẩm:{" "}
                  <span className="tw-font-extrabold tw-text-red">
                    {" "}
                    {Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(
                      order.price,
                    )}{" "}
                  </span>{" "}
                </div>

                <div>
                  Tổng tiền:{" "}
                  <span className="tw-font-extrabold tw-text-red">
                    {" "}
                    {Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(
                      watchAmount * order.price,
                    )}{" "}
                  </span>{" "}
                </div>
              </div>
            ) : null}
            <ButtonSubmit title="Đặt Mua" />
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default OrderPopup;
