export const routerConstant = {
  home: "/",
  login: "/login",
  page404: "/404",
  page500: "/500",
  about: "/gioi-thieu-chung",
  serviceIntroduction: "/gioi-thieu-dich-vu",
  service: "/dich-vu-rua-xe",
  carCareIntroduction: "/gioi-thieu-cham-soc-xe",
  carCare: "/cham-soc-xe",
  // serviceDetail: "dich-vu-rua-xe/slug",
  products: "/san-pham",
  // productsDetail: "/products/[id]",
  autoCoffee: "/auto-coffee",
  news: "/tin-tuc",
  // newsDetail: "news/[id]",
  information: "/thong-tin",
  userHistory: "/lich-su",
  profile: "/thong-tin-ca-nhan",
};

export const routerPrivate = [];

export const isRouterPrivate = (pathName) => {
  return routerPrivate.includes(pathName);
};
