import apiClient from "src/api/apiClient";

const userApi = {
  updateInfo(payload) {
    const url = "/update-customer";
    return apiClient.post(url, payload);
  },
  getInfo() {
    const url = "/profile";
    return apiClient.get(url);
  },
  uploadAvatar(payload) {
    const url = "/upload-avatar";
    return apiClient.post(url, payload);
  },
};
export default userApi;
