import apiClient from "src/api/apiClient";

const productApi = {
  getAll(params) {
    const url = "/products";
    return apiClient.get(url, { params });
  },

  getOne(params) {
    const url = `/products/${params}`;
    return apiClient.get(url);
  },

  getProductsByCategory(params) {
    const url = `/products-by-category`;
    return apiClient.get(url, { params });
  },
  orderProducts(payload) {
    const url = `/order`;
    return apiClient.post(url, payload);
  },
};
export default productApi;
