import apiClient from "src/api/apiClient";

const authApi = {
  login(payload) {
    const url = "/login";
    return apiClient.post(url, payload);
  },
  signUp(payload) {
    const url = "/signup";
    return apiClient.post(url, payload);
  },
  checkPhone(phoneNumber) {
    const url = `/prepare-for-booking?phone_number=${phoneNumber}`;
    return apiClient.get(url);
  },
  forgotPassword(payload) {
    const url = `/forgot-password`;
    return apiClient.post(url, payload);
  },
};
export default authApi;
