export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export function isVietnamesePhoneNumber(number) {
  return /((^(\+84|84|0|0084){1})(1|2|3|4|5|6|7|8|9))+([0-9]{8})$/.test(number);
}

export const isRegexVietnamesePhoneNumber = (phone) => {
  const isRegexVietnamesePhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

  return phone.match(isRegexVietnamesePhoneNumber) ? true : false;
};
