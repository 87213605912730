import { useRouter } from "next/router";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { authState, authStateDefault } from "src/recoil/authRecoilState";
import { removeCookie } from "src/utils/cookie";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const useAuth = () => {
  const router = useRouter();

  const [auth, setAuth] = useRecoilState(authState);

  const jwt = cookies.get("jwt");
  const phoneNumber = cookies.get("phoneNumber");

  useEffect(() => {
    if (!router.isReady) return;

    const isLoggedIn = Boolean(jwt && phoneNumber);
    if (isLoggedIn) {
      if (auth.status) return; // nếu trùng pre status thì không set

      setAuth({ status: true, jwt, phoneNumber });
      return;
    }

    if (!auth.status) return; // nếu trùng pre status thì không set

    setAuth(authStateDefault);
    removeCookie();

    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, phoneNumber, router.isReady]);
  return { auth };
};

export default useAuth;
