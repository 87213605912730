import Head from "next/head";

export const Layout = ({ children, title = "This is the default title" }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
      </Head>
      <div className="layout-app">
        <main className="tw-flex tw-flex-col tw-min-h-screen">{children}</main>
      </div>
    </>
  );
};
