import axios from "axios";
import qs from "qs";
import Cookies from "universal-cookie";
import { routerConstant } from "src/constants/routerConstant";
import { removeCookie } from "src/utils/cookie";

const baseURLApp =
  process.env.NODE_ENV === "production"
    ? process.env.NEXT_PUBLIC_API_PRO
    : process.env.NEXT_PUBLIC_API_DEV;

const apiClient = axios.create({
  baseURL: baseURLApp,
  headers: {
    "content-type": "application/json",
  },

  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
});

const cookies = new Cookies();

apiClient.interceptors.request.use(
  async (config) => {
    const jwt = cookies.get("jwt");

    if (jwt && config.headers) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      process.env.NODE_ENV !== "production" &&
        console.debug(
          response.status,
          response.config.method?.toUpperCase(),
          (response.config.baseURL || "") + response.config.url,
          response.data,
        );
      return response.data;
    }
    return response;
  },
  (error) => {
    process.env.NODE_ENV !== "production" &&
      console.debug(
        error.response?.status,
        error.response?.config?.method?.toUpperCase(),
        error.response?.config?.url,
        error,
        error.response?.data || error.response,
      );

    if (typeof window === "undefined") {
      throw error;
    } else {
      const response = error?.response?.data;
      const statusCode = error.response.status;
      if (statusCode === 401) {
        removeCookie();
        window.location.href = routerConstant.login;
      }
      if (statusCode === 500) {
        // window.location.href = routerConstant.page500;
        if (response?.code === 0) {
          // removeCookie();
        }
      }
    }
    return Promise.reject(error);
  },
);
export default apiClient;
