/* eslint-disable camelcase */
import { useQuery } from "@tanstack/react-query";
import HistoryApi from "src/api/history";
import queryKeys from "src/react-query/constants";

export const useGetBookingHistory = (params, options = {}) => {
  const { page, page_size, phone_number } = params;
  const isEnabled = !!params && !!page && !!page_size && !!phone_number;
  return useQuery(
    [`${queryKeys.history.bookingHistory}/${phone_number}`, params],
    () => HistoryApi.getBookingHistory(params),
    {
      enabled: isEnabled,
      ...options,
    },
  );
};
