import { handleResultApi } from "src/react-query/utils/handleResultApi";

export const MINUS = 60 * 1000;
export const TIME_CACHE_TIME = MINUS * 5; // 5 minutes

export const optionsQueryClient = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: TIME_CACHE_TIME,
      onError: (result) => {
        handleResultApi(result.response?.data);
      },
    },
    mutations: {
      onSuccess: (result) => {
        handleResultApi(result);
      },
      onError: (result) => {
        handleResultApi(result.response?.data);
      },
    },
  },
};
