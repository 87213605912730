const queryKeys = {
  news: {
    getAll: "news/getAll",
    getOne: "news/getOne",
    getNewsByType: "news/getNewsByType",
  },
  product: {
    getAll: "product/getAll",
    getOne: "product/getOne",
    getProductsByCategory: "product/getProductsByCategory",
  },
  service: {
    getAll: "service/getAll",
    getOne: "service/getOne",
    getServiceByCategory: "service/getServiceByCategory",
    getServiceCategories: "service/getServiceCategories",
    getServiceCategory: "service/getServiceCategory",
    getListTiming: "service/getListTiming",
  },
  user: {
    getUserInfo: "user/get-user-info",
  },
  carCategory: {
    getAll: "/car-categories",
  },
  history: {
    bookingHistory: "history/booking-history",
  },
};

export default queryKeys;
