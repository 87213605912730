import apiClient from "src/api/apiClient";

const serviceApi = {
  getAll(params) {
    const url = "/services";
    return apiClient.get(url, { params });
  },

  getOne(params) {
    const url = `/services/${params}`;
    return apiClient.get(url);
  },

  getServiceByCategory(params) {
    const url = `/services-by-category`;
    return apiClient.get(url, { params });
  },

  getServiceCategories(params) {
    const url = `/service-categories`;
    return apiClient.get(url, { params });
  },

  getServiceCategory(params) {
    const url = `/service-categories/${params}`;
    return apiClient.get(url);
  },

  getListTiming(params) {
    const url = `/get-list-timing`;
    return apiClient.get(url, { params });
  },
};
export default serviceApi;
