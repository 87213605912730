import { Form, Input, message, Modal } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useSetRecoilState } from "recoil";
import authApi from "src/api/authApi";
import { ButtonSubmit } from "src/common/BookingPopup/BookingPopup";
import { useGetUserInfo } from "src/react-query/hooks/user";
import { bookingPopupState, serviceBookingState } from "src/recoil/commonRecoilState";
import { isRegexVietnamesePhoneNumber } from "src/validations/index";
const PhonePopup = (props, ref) => {
  const setBookingPopup = useSetRecoilState(bookingPopupState);
  const setServiceBooking = useSetRecoilState(serviceBookingState);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const userQuery = useGetUserInfo();
  const dataUser = userQuery?.data?.data?.user;
  useImperativeHandle(ref, () => ({
    checkOpen: () => {
      if (dataUser?.phone_number) {
        setServiceBooking((serviceBooking) => ({
          ...serviceBooking,
          phoneNumber: dataUser?.phone_number,
          customerName: dataUser?.name,
          hasCustomer: true,
        }));
        setTimeout(() => {
          setBookingPopup(true);
        }, 500);
      } else {
        setOpen(true);
      }
    },
  }));

  const submitForm = async (data) => {
    const { phoneNumber } = data;
    let res = await authApi.checkPhone(phoneNumber);
    let customerName = res?.data?.customerName || "";
    setServiceBooking((serviceBooking) => ({
      ...serviceBooking,
      phoneNumber: phoneNumber,
      customerName: customerName,
      hasCustomer: Boolean(res?.data),
    }));
    if (res?.data) {
      message.success(`Đăng nhập thành công`);
    } else {
      message.success(res?.message);
    }
    handleCancel();
    setTimeout(() => {
      setBookingPopup(true);
    }, 500);
  };
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={handleCancel}
      bodyStyle={{ width: "calc(100% - 8px)" }}
      maskClosable={false}
    >
      <div className="tw-py-[26px] tw-px-[36px] tw-text-black ">
        <div className="tw-mb-[36px] tw-italic">
          <div className="tw-text-[34px] tw-leading-[40px] tw-uppercase tw-font-bold tw-mb-[30px] ">
            xin chào!
          </div>
          <p className="tw-text-[15px] tw-leading-[20px] tw-font-light">
            Cảm ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi.
          </p>
        </div>

        <div>
          <Form form={form} onFinish={submitForm}>
            <Form.Item
              label={<p className="tw-font-medium tw-text-[16px] tw-relative">Số điện thoại</p>}
              labelCol={{ span: 24 }}
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại",
                },
                () => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (!isRegexVietnamesePhoneNumber(value)) {
                      return Promise.reject("Nhập đúng định dạng số điện thoại");
                    } else return Promise.resolve();
                  },
                }),
              ]}
              className="tw-mb-[10px]"
            >
              <Input size="large" placeholder="0123456789" autoComplete="off" autoFocus={true} />
            </Form.Item>
            <ButtonSubmit title="Đặt lịch ngay" />
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(PhonePopup);
