import { useQuery } from "@tanstack/react-query";
import CarCategoryApi from "src/api/carCategoryApi";
import queryKeys from "src/react-query/constants";

export const useGetAllCarCategory = (params, options = []) => {
  return useQuery([queryKeys.carCategory.getAll, params], () => CarCategoryApi.getAll(params), {
    enabled: !!params,
    ...options,
  });
};
