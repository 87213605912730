/* eslint-disable camelcase */
import { useQuery } from "@tanstack/react-query";
import serviceApi from "src/api/serviceApi";
import queryKeys from "src/react-query/constants";

export const useGetAllService = (params, options = {}) => {
  return useQuery([queryKeys.service.getAll, params], () => serviceApi.getAll(params), {
    ...options,
  });
};

export const useGetOneService = (params, options = {}) => {
  return useQuery(
    [`${queryKeys.service.getOne}/${params}`, params],
    () => serviceApi.getOne(params),
    {
      enabled: !!params,
      ...options,
    },
  );
};

export const useGetServiceByCategory = (params, options = {}) => {
  const { slug } = params;
  const isEnabled = !!params && !!slug;
  return useQuery(
    [`${queryKeys.service.getServiceByCategory}/${slug}`, params],
    () => serviceApi.getServiceByCategory(params),
    {
      enabled: isEnabled,
      ...options,
    },
  );
};

export const useGetServiceCategories = (params, options = {}) => {
  return useQuery(
    [queryKeys.service.getServiceCategories, params],
    () => serviceApi.getServiceCategories(params),
    {
      ...options,
    },
  );
};

export const useGetServiceCategory = (params, options = {}) => {
  return useQuery(
    [`${queryKeys.service.getServiceCategory}/${params}`, params],
    () => serviceApi.getServiceCategory(params),
    {
      enabled: !!params,
      ...options,
    },
  );
};

export const useGetListTiming = (params, options = {}) => {
  const { date, service } = params;
  const isEnabled = !!params && !!date && !!service;
  return useQuery(
    [`${queryKeys.service.getListTiming}/${date}/${service}`, params],
    () => serviceApi.getListTiming(params),
    {
      enabled: isEnabled,
      ...options,
    },
  );
};
