import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import BookingPopup from "src/common/BookingPopup/BookingPopup";
import ConfirmPopup from "src/common/BookingPopup/ConfirmPopup";
import OrderPopup from "src/common/BookingPopup/OrderPopup";
import PhonePopup from "src/common/BookingPopup/PhonePopup";
import Loading from "src/common/Loading/Loading";
import AuthProvider from "src/layouts/AuthProvider";
import { Layout } from "src/layouts/Layout";
import { optionsQueryClient } from "src/react-query/configs/defaultOptions";
import "src/styles/globals.css";

function MyApp({ Component, pageProps }) {
  const [queryClient] = useState(() => new QueryClient(optionsQueryClient));
  global.phonePopupRef = useRef(null);
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <RecoilRoot>
          <AuthProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
            <OrderPopup />
            <PhonePopup ref={phonePopupRef} />
            <BookingPopup />
            <ConfirmPopup />

            {/* <Chatting /> */}

            <Loading />
          </AuthProvider>

          <ToastContainer />
        </RecoilRoot>
      </Hydrate>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default MyApp;
