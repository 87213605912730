/* eslint-disable camelcase */
import { Col, Collapse, DatePicker, Form, Input, message, Modal, Row, Image } from "antd";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IoTriangle } from "react-icons/io5";
import Slider from "react-slick";
import { useRecoilState, useSetRecoilState } from "recoil";
import bookingApi from "src/api/bookingApi";
import { serviceCategories } from "src/constants/services";
import useWindowSize from "src/hooks/useWindowSize";
// import { useLoginMutation } from "src/react-query/hooks/auth";
import { useGetAllCarCategory } from "src/react-query/hooks/carCategory";
import { useGetListTiming, useGetServiceByCategory } from "src/react-query/hooks/service";
import { useGetUserInfo } from "src/react-query/hooks/user";
import { isEmpty } from "lodash";
import { useGetBookingHistory } from "src/react-query/hooks/history";
import {
  bookingPopupState,
  confirmPopupState,
  serviceBookingState,
} from "src/recoil/commonRecoilState";
import { useLoading } from "src/hooks/useLoading";
const StepsForm = {
  carMarker: 0,
  service: 1,
  integrated: 2,
  date: 3,
  time: 3,
};

export const SampleNextArrow = (props) => {
  const { onClick } = props;

  return (
    <div
      onClick={onClick}
      className="tw-absolute tw-cursor-pointer tw-top-1/2 tw-right-[-5px] tw-translate-y-[-13px] tw-z-10"
    >
      <AiOutlineRight color="gray" fontSize={26} />
    </div>
  );
};

export const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="tw-absolute tw-cursor-pointer tw-top-1/2 tw-left-[-5px] tw-translate-y-[-13px] tw-z-10"
    >
      <AiOutlineLeft color="gray" fontSize={26} />
    </div>
  );
};

export const CustomCollapse = ({ children, placeholder, key, className, ...props }) => {
  return (
    <>
      <Collapse.Panel
        key={key}
        id={key}
        header={
          <div className="tw-text-black tw-text-[18px] tw-leading-[26px] tw-px-[16px] tw-py-[12px] tw-pr-[40px]">
            {placeholder}
          </div>
        }
        className={classNames("tw-bg-white tw-mb-[12px] !tw-border-0 custom-panel", className)}
        {...props}
      >
        {children}
      </Collapse.Panel>

      <style jsx global>{`
        .custom-panel::before {
          content: "";
          width: 15px;
          height: 15px;
          position: absolute;
          background: transparent;
          border: 2px solid #bd1300;
          left: -26px;
          top: 20px;
          border-radius: 100%;
        }
        .custom-panel {
          position: relative;
        }
        .active-panel::before {
          content: "";
          width: 15px;
          height: 15px;
          position: absolute;
          background: #bd1300;
          left: -26px;
          top: 20px;
          border-radius: 100%;
        }
        .active-panel::after {
          content: "";
          width: 1px;
          height: calc(100% - 22px);
          position: absolute;
          background: #bd1300;
          left: -19px;
          top: 45px;
          border-radius: 100%;
        }
        .last-panel::after {
          display: none;
        }
      `}</style>
    </>
  );
};
export const ButtonSubmit = ({ title = "Đặt lịch ngay" }) => {
  return (
    <button
      type="submit"
      className="tw-w-full tw-bg-button tw-uppercase text-center tw-text-[26px] tw-leading-[26px] tw-py-[10px] tw-text-white tw-tracking-[4px] tw-mt-[12px] custom-hover"
    >
      {title}
    </button>
  );
};

export const CustomDate = ({ dateString }) => {
  let date = new Date(dateString);
  // get deffirent from today
  let diffDay = date.DiffToday();
  let diffDayString = "";
  switch (diffDay) {
    case 0:
      diffDayString = "Hôm nay.";
      break;
    case 1:
      diffDayString = "Ngày mai.";
      break;
    default:
      diffDayString = diffDay <= 10 ? `${diffDay} ngày sau.` : "";
      break;
  }

  //get DayOfweek
  let dayOfWeek = date.getDay();
  let dayOfWeekString = "";
  switch (dayOfWeek) {
    case 0:
      dayOfWeekString = "Chủ Nhật";
      break;

    default:
      dayOfWeekString = `Thứ ${dayOfWeek + 1}`;
      break;
  }

  //get date format date.month
  let dateOfdate = ("0" + date.getDate()).slice(-2);
  let monthOfdate = ("0" + (date.getMonth() + 1)).slice(-2);
  let dateMonthString = `${dateOfdate}.${monthOfdate}`;

  return `${diffDayString} ${dayOfWeekString} (${dateMonthString})`;
};

Date.prototype.DiffToday = function () {
  const today = moment(new Date()).startOf("day");
  const diffTime = Math.abs(today - this);
  if (diffTime < 1000 * 60 * 60 * 24) return 0;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
export const convertHHmm = (timing) => {
  if (Number.isInteger(timing)) {
    return `${timing}:00`;
  }

  const hours = timing.toString().split(".")[0];
  const minutes = (timing % 1) * 60;
  return `${hours}:${minutes}`;
};
export const convertSlider = (blocks, length = 9) => {
  if (!blocks || !blocks.length) {
    return;
  }
  let listSlider = [];
  for (let i = 0; i < blocks.length; i += length) {
    let slider = blocks.slice(i, i + length);
    listSlider.push(slider);
  }
  return listSlider;
};
export const getInitialSlideByTime = () => {
  const date = new Date();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const minuteToFloat = minute / 60;
  const hourMinuteToFloat = Number(hour) + Number(minuteToFloat);
  let initialSlide = 0;
  if (10 < hourMinuteToFloat) {
    initialSlide = 1;
  }
  if (12.25 < hourMinuteToFloat) {
    initialSlide = 2;
  }
  if (14.5 < hourMinuteToFloat) {
    initialSlide = 3;
  }
  if (16.75 < hourMinuteToFloat) {
    initialSlide = 4;
  }
  if (19 < hourMinuteToFloat) {
    initialSlide = 5;
  }
  return initialSlide;
};

const BookingPopup = () => {
  const [loading, setLoading] = useState(false);
  useLoading(loading);

  const [form] = Form.useForm();

  const [showError, setShowError] = useState(false);

  const watchCarMarker = Form.useWatch("carMarker", form);
  const watchService = Form.useWatch("service", form);
  const watchIntegrated = Form.useWatch("integrated", form);
  const watchDate = Form.useWatch("date", form);
  const watchTime = Form.useWatch("time", form);

  const textError = useMemo(() => {
    const err = [];
    if (showError) {
      if (!watchCarMarker) {
        err.push("loại xe");
      }
      if (!watchCarMarker) {
        err.push("dịch vụ rửa xe xe");
      }
      if (!watchDate) {
        err.push("ngày đặt dịch vụ");
      }
      if (!watchTime) {
        err.push("giờ đặt dịch vụ");
      }
    }
    return isEmpty(err) ? "" : "Vui lòng chọn " + err.join(", ");
  }, [showError, watchCarMarker, watchDate, watchTime]);

  const { width } = useWindowSize();

  // const loginMutation = useLoginMutation();
  const userQuery = useGetUserInfo();
  const dataUser = userQuery?.data?.data?.user;

  const [bookingPopup, setBookingPopup] = useRecoilState(bookingPopupState);
  const [serviceBooking, setServiceBooking] = useRecoilState(serviceBookingState);

  const setConfirmPopup = useSetRecoilState(confirmPopupState);

  // get lich-su-dat-lich
  const [pagination] = useState({ page_size: 999, page: 1 });
  const bookingHistoryQuery = useGetBookingHistory({
    page_size: pagination?.page_size,
    page: pagination?.page,
    phone_number: dataUser?.phone_number.toString(),
  });
  const dataBookingHistory = bookingHistoryQuery?.data?.data?.["booking-history"] || {};
  const latestBooking = (Object.values(dataBookingHistory)[0] || [])[0];

  const initialValues = useMemo(() => {
    return {
      date: new Date(),
      service: serviceBooking?.service || latestBooking?.services[0],
      integrated: serviceBooking?.integrated || latestBooking?.services[1],
    };
  }, [serviceBooking, latestBooking]);
  const customerName = serviceBooking?.customerName;

  const [currentStep, setCurrentStep] = useState(0);
  const [activeKey, setActiveKey] = useState(0);
  const [step, setStep] = useState(0);
  const [stateForm, setStateForm] = useState({
    ...initialValues,
  });

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: getInitialSlideByTime(),
    autoplay: false,
    arrow: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    setStateForm({
      ...initialValues,
    });
  }, [bookingPopup, initialValues]);
  // get TimeBlock
  const listTimingQuery = useGetListTiming({
    date: moment(stateForm.date).format("YYYY-MM-DD"),
    service: stateForm?.service?.slug || "",
  });
  const dataListTimingQuery = listTimingQuery?.data?.data?.listTiming || [];
  const blockTimes = dataListTimingQuery.map((item) => {
    return {
      ...item,
      timingRender: convertHHmm(item.timing),
    };
  });
  const listSlider = convertSlider(blockTimes, 9);

  // get dich vu rua xe options
  const serviceCategoriesQuery = useGetServiceByCategory({
    slug: serviceCategories.dichVuRuaXe,
  });
  const dataServiceCategories = useMemo(
    () => serviceCategoriesQuery?.data?.data?.listServiceByCategory || [],
    [serviceCategoriesQuery?.data?.data?.listServiceByCategory],
  );
  const dataServiceCategoriesFilter = useMemo(
    () =>
      dataServiceCategories.map((item) => {
        return {
          ...item,
          price: isEmpty(item.service_car_categories)
            ? 0
            : item.service_car_categories?.find((d) => d.car_category_id === watchCarMarker?.id)
                ?.price,
        };
      }),
    [dataServiceCategories, watchCarMarker?.id],
  );
  // get dich vu khac options
  const integratedQuery = useGetServiceByCategory({
    slug: serviceCategories.chamSocXe,
  });
  const dataIntegrated = useMemo(
    () => integratedQuery?.data?.data?.listServiceByCategory || [],
    [integratedQuery?.data?.data?.listServiceByCategory],
  );
  const dataIntegratedFilter = useMemo(
    () =>
      dataIntegrated.map((item) => {
        return {
          ...item,
          price: isEmpty(item.service_car_categories)
            ? 0
            : item.service_car_categories?.find((d) => d.car_category_id === watchCarMarker?.id)
                ?.price,
        };
      }),
    [dataIntegrated, watchCarMarker?.id],
  );
  // get loai xe
  const CarCategories = useGetAllCarCategory({});
  const dataCarCateGories = CarCategories?.data?.data?.listCarCategories || [];

  const onCancel = () => {
    setBookingPopup(false);
    form.resetFields();
    setStateForm({
      date: new Date(),
    });
    setCurrentStep(0);
    setStep(0);
    setActiveKey(0);
  };

  const TagOption = ({ options, field }) => {
    return (
      <>
        <div className="tw-mt-[15px] tw-flex tw-flex-wrap tw-mx-[-5px] tw-gap-y-[5px]">
          {options.map((option) => {
            return (
              <div key={option.id} className="md:tw-w-1/3 tw-w-1/2 tw-px-[5px]">
                <div
                  className={`tw-cursor-pointer tw-w-full tw-h-full tw-bg-grayCd tw-text-[14px] tw-leading-[26px] tw-uppercase tw-text-center tw-px-[5px] tw-py-[10px] tw-border-[2px] tw-border-solid ${
                    stateForm?.carMarker?.id == option?.id ? " tw-border-red" : " tw-border-white"
                  }`}
                  onClick={() => {
                    if (field === "carMarker") {
                      setCurrentStep(0);
                      setStep(0);
                      setActiveKey(1);
                      setStateForm({
                        date: new Date(),
                        [field]: option,
                      });
                      form.setFieldValue(field, option);
                      form.setFieldValue("service", undefined);
                      form.setFieldValue("integrated", undefined);
                      form.setFieldValue("date", new Date());
                      return;
                    }

                    onChangeForm(field, option);
                    if (field === "service") {
                      setCurrentStep(2);
                      setStep(2);
                      setActiveKey(2);
                    }
                    if (field === "integrated") {
                      setCurrentStep(2);
                      setStep(2);
                      setActiveKey(3);
                    }
                    if (field === "date") {
                      setCurrentStep(3);
                      setStep(3);
                      setActiveKey(3);
                    }
                  }}
                >
                  {option.name}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };
  const CardOption = ({ options, field }) => {
    return (
      <>
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-[8px] tw-mt-[15px] custom-hidden-scroll">
          {options.map((option) => {
            return (
              <div
                className={`tw-h-[100px] tw-w-full tw-bg-greyECE tw-flex tw-cursor-pointer tw-border-solid tw-border-2 ${
                  stateForm && stateForm[field] && stateForm[field]?.id == option?.id
                    ? " tw-border-red"
                    : " tw-border-white"
                }`}
                key={option.id}
                onClick={() => {
                  onChangeForm(field, option);
                  if (field === "service") {
                    setCurrentStep(1);
                    setStep(1);
                    setActiveKey(2);
                  }

                  if (field === "integrated") {
                    setCurrentStep(2);
                    setStep(2);
                    setActiveKey(3);
                  }
                }}
              >
                <div className="tw-shrink-0 tw-w-[160px] tw-h-[96px]">
                  {option?.image && option.image != "false" ? (
                    <Image
                      src={option.image}
                      alt={option.name}
                      width={160}
                      height={96}
                      className="tw-object-cover"
                      preview={false}
                    />
                  ) : null}
                </div>

                <div className="tw-flex-1 md:tw-px-[14px] tw-pl-[10px] tw-pr-[5px] tw-my-auto tw-max-w-full tw-max-h-full tw-truncate">
                  <h3 className="tw-text-[20px] tw-leading-[23px] tw-w-fit tw-whitespace-pre-wrap overFlow-2-line">
                    {option.name}
                  </h3>
                  <div className="tw-font-extrabold tw-text-black">
                    {Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(
                      option.price,
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <style jsx global>{`
          .custom-hidden-scroll::-webkit-scrollbar {
            display: none;
          }

          .custom-hidden-scroll {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
        `}</style>
      </>
    );
  };
  const BlockTime = ({ blocks }) => {
    return (
      <Form.Item name="time" className="tw-mb-[0px]">
        <Row gutter={[10, 10]}>
          {blocks.map((item) => {
            return (
              <Col key={item.block} md={8} sm={12} xs={12}>
                <button
                  key={item.block}
                  disabled={!item.status}
                  type="button"
                  className={`tw-w-full tw-px-[18px] tw-py-[15px] tw-bg-greyECE tw-text-[25px] tw-leading-[18px] tw-font-bold ${
                    item.block == stateForm?.time?.block
                      ? "tw-text-white tw-bg-button"
                      : !item.status
                      ? "tw-text-grayCd"
                      : "tw-text-gray85"
                  }`}
                  onClick={() => {
                    onChangeForm("time", item);
                  }}
                >
                  {item.timingRender}
                </button>
              </Col>
            );
          })}
        </Row>
      </Form.Item>
    );
  };

  const SelectCarMarker = (
    <>
      <CustomCollapse
        placeholder={watchCarMarker ? `Xe ${watchCarMarker?.name}` : "Chọn loại xe"}
        key={StepsForm.carMarker}
        className={{ "active-panel": step >= StepsForm.carMarker }}
      >
        <Form.Item
          name="carMarker"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn loại xe",
            },
          ]}
          className="tw-mb-[14px]"
        >
          <TagOption options={dataCarCateGories} field="carMarker" />
        </Form.Item>
      </CustomCollapse>
    </>
  );

  const SelectService = (
    <CustomCollapse
      placeholder={watchService ? watchService?.name : "Chọn dịch vụ rửa xe"}
      key={StepsForm.service}
      className={{ "active-panel": step >= StepsForm.service }}
    >
      <Form.Item
        name="service"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn dịch vụ",
          },
        ]}
        className="tw-mb-[14px]"
      >
        <CardOption options={dataServiceCategoriesFilter} field="service" />
      </Form.Item>
    </CustomCollapse>
  );

  const SelectIntegrated = (
    <CustomCollapse
      placeholder={watchIntegrated ? watchIntegrated?.name : "Dịch vụ khác (không bắt buộc)"}
      key={StepsForm.integrated}
      className={{ "active-panel": step >= StepsForm.integrated }}
    >
      <Form.Item name="integrated" className="tw-mb-[14px]">
        {!dataIntegratedFilter || !dataIntegratedFilter.length ? (
          <div>Dữ liệu trống</div>
        ) : (
          <CardOption options={dataIntegratedFilter} field="integrated" />
        )}
      </Form.Item>
    </CustomCollapse>
  );

  const SelectDate = (
    <CustomCollapse
      placeholder={stateForm?.date ? <CustomDate dateString={stateForm?.date} /> : ""}
      key={StepsForm.date}
      className={{ "active-panel": step >= StepsForm.date, "last-panel": true }}
    >
      <Form.Item
        name="date"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn thời gian",
          },
        ]}
        className="tw-mb-[14px]"
      >
        <DatePicker
          allowClear={false}
          format="DD/MM/YYYY"
          onChange={(date) => {
            onChangeForm("date", date);
            setCurrentStep(3);
            setStep(3);
            setActiveKey(3);
          }}
          value={moment(stateForm.date)}
          size="large"
          className="tw-w-full tw-my-[10px]"
          placeholder="Ngày đặt lịch"
          disabledDate={(current) =>
            current < moment().startOf("day") || current > moment().add(7, "d").endOf("day")
          }
        />
        {listSlider && listSlider.length ? (
          <Slider {...settings} className="tw-px-[22px] tw-mt-[10px]">
            {listSlider.map((block, index) => {
              return <BlockTime key={index} blocks={block} />;
            })}
          </Slider>
        ) : (
          <div>Không còn lịch trống </div>
        )}
      </Form.Item>
      {/* <style jsx global>{`
        .ant-picker-input > input:focus,
        .ant-picker-input > input-focused,
        .ant-picker-focused,
        .ant-picker-input > input:hover,
        .ant-picker:hover,
        .ant-picker-focused {
          border-color: #bd1300 !important;
        }
      `}</style> */}
    </CustomCollapse>
  );
  const priceService = Number(watchService?.price) || 0;
  const priceIntegrated = Number(watchIntegrated?.price) || 0;
  const price = priceService + priceIntegrated;

  const PriceService = (
    <div className="tw-text-red tw-flex tw-gap-[10px] tw-text-[20px] tw-leading-[20px] tw-italic">
      <div>Thành tiền: </div>
      <div>{Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(price)}</div>
    </div>
  );
  const TextFieldComments = (
    <div>
      <Form.Item name="comment" className="tw-mt-[14px] tw-mb-0">
        <Input.TextArea placeholder="Ghi chú cho cửa hàng" autoSize={{ minRows: 3 }} />
      </Form.Item>
      <style jsx global>{`
        textarea::placeholder {
          font-size: 16px;
          font-weight: normal;
          color: #bd1300 !important;
        }
        .ant-input:hover,
        .ant-input:focus,
        .ant-input-focused {
          border-color: #bd1300;
        }
      `}</style>
    </div>
  );
  const submitForm = async (data) => {
    setLoading(true);
    try {
      setCurrentStep(StepsForm.submit);
      let dataBooking = {
        phone_number: serviceBooking.phoneNumber,
        car_category_id: data.carMarker.id,
        service_required: data.service.slug,
        service_optional: data.integrated?.slug,
        date: moment(data.date).format("YYYY-MM-DD"),
        start_time: Number(data?.time?.timing),
        comment: data?.comment,
      };
      let response = await bookingApi.postFormBooking(dataBooking);
      if (response.success) {
        setServiceBooking(data);
        setConfirmPopup({
          show: true,
          status: "success",
          isLoggedIn: Boolean(dataUser),
          payload: {
            phoneNumber: serviceBooking.phoneNumber,
            hasCustomer: serviceBooking?.hasCustomer,
          },
        });

        // if (!dataUser) {
        //   const payload = {
        //     phone_number: serviceBooking.phoneNumber,
        //     password: serviceBooking.phoneNumber,
        //   };
        //   loginMutation.mutate(payload);
        // }

        message.success("Gửi thông tin thành công");
        setTimeout(onCancel, 300);
      } else {
        message.error("Gửi thông tin thất bại");
      }
    } catch (error) {
      console.error(error);
      message.error("Gửi thông tin thất bại");
    }
    setLoading(false);
  };

  const onChangeForm = (field, value) => {
    form.setFieldValue(field, value);
    setStateForm((prevState) => ({ ...prevState, [field]: value }));
    if (StepsForm[field] > currentStep) {
      setCurrentStep(StepsForm[field]);
    }
  };

  return (
    <Modal
      open={bookingPopup}
      footer={null}
      onCancel={onCancel}
      width={width > 768 ? 525 : null}
      maskClosable={false}
    >
      <div className="tw-py-[26px] md:tw-px-[46px] tw-pl-[25px] tw-text-black ">
        <div className="tw-mb-[24px] tw-italic">
          <div className="tw-text-[34px] tw-leading-[40px] tw-uppercase tw-font-bold tw-mb-[12px] ">
            xin chào!
          </div>
          <div className="tw-text-[23px] tw-leading-[23px] tw-font-bold ">
            {customerName ? customerName : "Chào anh chị"}
          </div>
          <p className="tw-text-[15px] tw-leading-[20px] tw-font-light">
            Cảm ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi. Nếu bạn muốn thay đổi dịch vụ
            vui lòng chọn lại bên dưới.
          </p>
        </div>

        <div className="tw-relative">
          <Form
            form={form}
            onFinish={submitForm}
            onFinishFailed={() => {
              setShowError(true);
            }}
            initialValues={initialValues}
          >
            <Collapse
              expandIconPosition="end"
              bordered={false}
              expandIcon={({ isActive }) => (
                <IoTriangle
                  className="tw-text-gray37"
                  style={{
                    transform: !isActive
                      ? "rotate(180deg) translateY(8px)"
                      : "rotate(0deg) translateY(-8px)",
                  }}
                />
              )}
              className="custom-collapse"
              accordion={true}
              defaultActiveKey={StepsForm.carMarker}
              activeKey={activeKey}
              onChange={(e) => {
                setActiveKey(e);
                // if (e > step) {
                //   setStep(e);
                // }
              }}
            >
              {SelectCarMarker}
              {SelectService}
              {SelectIntegrated}
              {SelectDate}
              {price ? PriceService : null}
              {TextFieldComments}

              <div className="tw-text-red tw-mt-5">{showError && textError}</div>

              <button
                type="submit"
                className="tw-w-full tw-bg-button tw-uppercase text-center tw-text-[26px] tw-leading-[26px] tw-py-[10px] tw-text-white tw-tracking-[4px] tw-mt-[12px] custom-hover"
              >
                Đặt lịch ngay
              </button>
            </Collapse>
          </Form>
        </div>
        <style jsx global>{`
          .custom-collapse .ant-collapse-header {
            border: 2px solid #373737;
            background: white;
            padding: 0px !important;
          }
          .custom-collapse .ant-collapse-content-box {
            padding: 5px 0px;
          }
          div.custom-panel:last-of-type {
            background: yellow !important;
          }
        `}</style>
      </div>
    </Modal>
  );
};
export default BookingPopup;
