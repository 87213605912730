import { atom } from "recoil";

export const loadingState = atom({
  key: "loading",
  default: false,
});

export const loadingActionState = atom({
  key: "loadingActionState",
  default: false,
});

export const bookingPopupState = atom({
  key: "bookingPopup",
  default: false,
});

export const confirmPopupState = atom({
  key: "confirmPopup",
  default: {
    show: false,
    status: "success",
    isLoggedIn: true,
    payload: {
      phoneNumber: "",
    },
  },
});

export const serviceBookingState = atom({
  key: "serviceBooking",
  default: null,
});

export const signUpModalState = atom({
  key: "signUpModalState",
  default: false,
});

export const signInModalState = atom({
  key: "signInModalState",
  default: false,
});

export const OrderPopupState = atom({
  key: "orderPopup",
  default: {
    show: false,
    order: {
      type: "",
      name: "",
      price: "",
      id: "",
      quantity: 1,
    },
  },
});

export const FORGOT_PASSWORD_STATUS = {
  FORM: 1,
  NOTIFICATION: 2,
};

export const forgotPasswordModalState = atom({
  key: "forgotPasswordModalState",
  default: {
    isOpen: false,
    state: null,
    status: FORGOT_PASSWORD_STATUS.FORM,
  },
});
