import { useMutation, useQuery } from "@tanstack/react-query";
import userApi from "src/api/userApi";
import { TIME_CACHE_TIME } from "src/react-query/configs/defaultOptions";
import queryKeys from "src/react-query/constants";
import Cookies from "universal-cookie";
import { handleResultApi } from "src/react-query/utils/handleResultApi";
import Router from "next/router";
import { routerConstant } from "src/constants/routerConstant";

const cookies = new Cookies();

export const useGetUserInfo = (options) => {
  const jwt = cookies.get("jwt");
  return useQuery([queryKeys.user.getUserInfo], userApi.getInfo, {
    refetchInterval: TIME_CACHE_TIME,
    refetchOnMount: false,
    enabled: Boolean(jwt),
    // refetchOnMount: false,
    ...options,
  });
};

export const useUpdateInfoMutation = (options) => {
  const userQuery = useGetUserInfo({ enabled: false });

  return useMutation({
    mutationFn: userApi.updateInfo,
    onSuccess: async (response) => {
      await userQuery.refetch();

      handleResultApi(response);
      Router.push(routerConstant.profile);
    },
    onError: (error) => {
      handleResultApi(error.response?.data);
    },
    ...options,
  });
};

export const useUploadAvatarMutation = (options) => {
  return useMutation({
    mutationFn: userApi.uploadAvatar,
    onSuccess: async () => {
      // handleResultApi(response);
    },
    onError: (error) => {
      handleResultApi(error.response?.data);
    },
    ...options,
  });
};
