/* eslint-disable camelcase */
import { useQuery, useMutation } from "@tanstack/react-query";
import productApi from "src/api/productApi";
import queryKeys from "src/react-query/constants";
import { handleResultApi } from "src/react-query/utils/handleResultApi";

export const useGetAllProduct = (params, options = {}) => {
  const { page, page_size } = params;
  const isEnabled = !!params && !!page && !!page_size;
  return useQuery([queryKeys.product.getAll, params], () => productApi.getAll(params), {
    enabled: isEnabled,
    ...options,
  });
};

export const useGetOneProduct = (params, options = {}) => {
  return useQuery(
    [`${queryKeys.product.getOne}/${params}`, params],
    () => productApi.getOne(params),
    {
      enabled: !!params,
      ...options,
    },
  );
};

export const useGetProductsByCategory = (params, options = {}) => {
  const { page, page_size, slug } = params;
  const isEnabled = !!params && !!slug && !!page && !!page_size;
  return useQuery(
    [`${queryKeys.product.getProductsByCategory}/${slug}`, params],
    () => productApi.getProductsByCategory(params),
    {
      enabled: isEnabled,
      ...options,
    },
  );
};

export const useOrderProducts = (options) => {
  return useMutation({
    mutationFn: productApi.orderProducts,
    onSuccess: async (response) => {
      handleResultApi(response);
    },
    onError: (error) => {
      handleResultApi(error.response?.data);
    },
    ...options,
  });
};
